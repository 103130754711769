import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import rbacCheck from '../../../../rbac/rbacCheck';
import CreateProductFormModal from './CreateProductFormModal';
import useApiCall from '../../../../hooks/useApiCall';
import Auth from '../../../../api/auth';
import Table from '../../../../components/Table';

const Products = () => {
  const { projectId } = useParams();
  const intl = useIntl();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user);

  const [createModalOpened, setCreateModalOpened] = useState(false);
  const [products, setProducts] = useState(null);

  const getProductsCall = useApiCall(Auth.fetchV2Products);

  const canCreateProduct = (products?.length === 0
  || products?.some((product) => product.type !== 'evaluation'))
  && rbacCheck(userData.roles, '/products:POST');

  useEffect(() => {
    const getProducts = async () => {
      const [data] = await getProductsCall({ projectId });

      if (data) setProducts(data);
    };

    getProducts();
  }, []);

  const fields = [
    {
      title: 'Name',
      id: 'name',
    },
    {
      title: 'Description',
      id: 'description',
    },
    {
      title: 'Type',
      id: 'type',
      type: 'options',
    },
    {
      title: 'Juicegroup',
      id: 'juiceGroup',
    },
    {
      title: 'Approved',
      id: 'approved',
      type: 'options',
    },
    {
      title: 'Tags',
      id: 'tags',
      type: 'tags',
    },
  ];

  const rows = products?.map((product) => ({
    key: product._id,
    onRowClick: () => navigate(`/products/${product._id}`),
    data: [
      {
        field: 'name',
        value: product.name,
      },
      {
        field: 'description',
        value: product.description,
      },
      {
        field: 'type',
        value: product.type,
      },
      {
        field: 'juiceGroup',
        value: product.details.juiceGroup,
      },
      {
        field: 'approved',
        value: product.approved ? 'True' : 'False',
      },
      {
        field: 'tags',
        type: 'tags',
        value: product.details.tags,
      },
    ],
  }));

  const getCreateProductTooltip = () => {
    const evaluationProductExists = products?.some((product) => product.type === 'evaluation');
    return evaluationProductExists
      ? intl.formatMessage({ id: 'evaluationProductCreationDisabled' })
      : intl.formatMessage({ id: 'productCreationPermissionErr' });
  };

  return (
    <>
      {createModalOpened && (
        <CreateProductFormModal
          onSubmit={(newProduct) => setProducts((prev) => [...prev, newProduct])}
          onCancel={() => setCreateModalOpened(false)}
        />
      )}
      <Table
        header="Products"
        fields={fields}
        rows={rows}
        createAction={{
          disabled: !canCreateProduct,
          onClick: () => setCreateModalOpened(true),
          tooltip: canCreateProduct ? '' : getCreateProductTooltip(),
        }}
      />
    </>
  );
};

export default Products;
