import {
  FormControl as FormControlMUI,
  FormHelperText as FormHelperTextMUI,
  InputLabel as InputLabelMUI,
  MenuItem as MenuItemMUI,
  Select as SelectMUI,
} from '@mui/material';
import { PropTypes } from 'prop-types';

export const Select = ({
  id,
  name,
  value,
  onChange,
  onBlur,
  label,
  menuItems,
  disabled,
  helperText,
}) => {
  const selectedValue = menuItems.some(({ value: v }) => v === value) ? value : '';

  return (
    <FormControlMUI>
      <InputLabelMUI id={id}>{label}</InputLabelMUI>
      <SelectMUI
        variant="outlined"
        id={id || name}
        name={name}
        value={selectedValue}
        onChange={(e) => onChange(e.target.value ?? '')}
        onBlur={onBlur}
        label={label}
        disabled={disabled}
      >
        <MenuItemMUI key="null_value" value="">
          -- No selection --
        </MenuItemMUI>
        {menuItems?.map((item) => (
          <MenuItemMUI
            key={item.value}
            value={item.value}
          >
            {item.label}
          </MenuItemMUI>
        ))}
      </SelectMUI>
      <FormHelperTextMUI>{helperText}</FormHelperTextMUI>
    </FormControlMUI>
  );
};

Select.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  label: PropTypes.string.isRequired,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
    }),
  ),
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
};

Select.defaultProps = {
  id: '',
  value: '',
  onBlur: () => {},
  menuItems: [],
  disabled: false,
  helperText: '',
};
