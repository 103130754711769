import { IconButton, InputAdornment, TextField as TextFieldMUI } from '@mui/material';
import PropTypes from 'prop-types';
import { TuneIcon } from '../icons/IconLibrary';

export const SearchField = ({
  id,
  label,
  name,
  value,
  onChange,
  onBlur,
  placeholder,
  autoFocus,
  disabled,
  helperText,
  multiline,
  rows,
  inputRef,
  onFilterClick,
  advancedSearch,
}) => (
  <TextFieldMUI
    type="search"
    id={id || name}
    label={label}
    name={name}
    value={value}
    onChange={onChange}
    onBlur={onBlur}
    placeholder={placeholder}
    autoFocus={autoFocus}
    disabled={disabled}
    helperText={helperText}
    multiline={multiline}
    rows={rows}
    inputRef={inputRef}
    InputProps={{
      endAdornment: advancedSearch && (
        <InputAdornment position="end">
          <IconButton
            aria-label="Advanced search"
            onClick={onFilterClick}
            edge="end"
          >
            <TuneIcon />
          </IconButton>
        </InputAdornment>
      ),
    }}
  />
);

SearchField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  inputRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  onFilterClick: PropTypes.func,
  advancedSearch: PropTypes.bool,
};

SearchField.defaultProps = {
  id: '',
  onChange: () => {},
  onBlur: () => {},
  placeholder: '',
  autoFocus: false,
  disabled: false,
  helperText: '',
  multiline: false,
  rows: 1,
  inputRef: null,
  advancedSearch: true,
  value: undefined,
  onFilterClick: () => {},
};
