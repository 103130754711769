import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import Button from '../Buttons/Button';
import Search from './Search';
import Tooltip from '../Tooltip/Tooltip';

const Header = ({
  header,
  setFilter,
  setCurrentPage,
  createAction,
  exportData,
  search,
  rows,
  searchedRows,
  setSearchedRows,
  fields,
  advancedSearch,
  isPaginated,
}) => (
  <Grid
    container
    justifyContent="space-between"
    pb={1}
  >
    <Grid item>
      <Typography variant="h4" color="primary">{header}</Typography>
    </Grid>
    <Grid item>
      <Grid
        container
        justifyContent="flex-end"
        alignItems="center"
        columnSpacing={1}
      >
        {createAction && (
          <Grid item>
            <Tooltip title={createAction.tooltip}>
              <Button
                disabled={createAction.disabled}
                onClick={createAction.onClick}
                label="Create"
              />
            </Tooltip>
          </Grid>
        )}
        {exportData && (
          <Grid item>
            <Tooltip title={exportData.tooltip}>
              <Button
                href={URL.createObjectURL(exportData.file)}
                download={exportData.fileName}
                label={exportData.name}
              />
            </Tooltip>
          </Grid>
        )}
        {search && (
          <Search
            setCurrentPage={setCurrentPage}
            rows={rows}
            searchedRows={searchedRows}
            setSearchedRows={setSearchedRows}
            fields={fields}
            advancedSearch={advancedSearch}
            setFilter={setFilter}
            isPaginated={isPaginated}
          />
        )}
      </Grid>
    </Grid>
  </Grid>
);

Header.propTypes = {
  header: PropTypes.string.isRequired,
  setFilter: PropTypes.func.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  createAction: PropTypes.shape({
    tooltip: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
  }),
  exportData: PropTypes.shape({
    tooltip: PropTypes.string,
    file: PropTypes.instanceOf(Blob),
    fileName: PropTypes.string,
    name: PropTypes.string,
  }),
  search: PropTypes.bool.isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({
      field: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
        PropTypes.number,
      ]),
    })),
  })).isRequired,
  searchedRows: PropTypes.arrayOf(PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({
      field: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
        PropTypes.number,
      ]),
    })),
  })).isRequired,
  setSearchedRows: PropTypes.func.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  })).isRequired,
  advancedSearch: PropTypes.bool.isRequired,
  isPaginated: PropTypes.bool.isRequired,
};

Header.defaultProps = {
  createAction: null,
  exportData: null,
};

export default Header;
