import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { MultiSelect, Select, TextField } from '../InputFields';

const FilterFields = ({
  field,
  rows,
  onChange,
  values,
}) => {
  const getMenuItems = (filterField, dataRows) => {
    const uniqueItems = new Set();
    dataRows.forEach((row) => {
      const item = row.data?.find((i) => i.field === filterField.id);
      if (item) uniqueItems.add(item.value);
    });
    return [...uniqueItems].map((value) => ({ value, label: value }));
  };

  const menuItems = useMemo(() => {
    if (field.type === 'options') {
      return getMenuItems(field, rows);
    }

    if (field.type === 'tags') {
      return [...new Set(rows.flatMap(
        (row) => row.data.find((data) => data.field === field.id)?.value || [],
      ))].map((value) => ({ value, label: value }));
    }

    return [];
  }, [field, rows]);

  if ((field.type === 'options' || field.type === 'tags') && menuItems.length <= 2) {
    return (
      <Select
        name={field.id}
        label={field.title}
        value={values[0] || ''}
        onChange={onChange}
        menuItems={menuItems}
      />
    );
  }

  if (field.type === 'options' || field.type === 'tags') {
    return (
      <MultiSelect
        name={field.id}
        label={field.title}
        onChange={onChange}
        value={values}
        menuItems={menuItems}
      />
    );
  }

  return (
    <TextField
      label={field.title}
      name={field.id}
      value={values[0] || ''}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};

FilterFields.propTypes = {
  field: PropTypes.shape({
    type: PropTypes.string,
    title: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({
      field: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
      ]),
    })),
  })).isRequired,
  values: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    ),
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default FilterFields;
