import {
  FormControl as FormControlMUI,
  FormHelperText as FormHelperTextMUI,
  InputLabel as InputLabelMUI,
  MenuItem as MenuItemMUI,
  Select as SelectMUI,
} from '@mui/material';
import PropTypes from 'prop-types';

export const MultiSelect = ({
  id,
  label,
  name,
  value,
  onChange,
  onBlur,
  menuItems,
  disabled,
  helperText,
}) => (
  <FormControlMUI>
    <InputLabelMUI id={id}>{label}</InputLabelMUI>
    <SelectMUI
      multiple
      id={id || name}
      name={name}
      value={value}
      onChange={(e) => {
        const newValue = e.target.value.includes(null) ? [] : e.target.value;
        onChange(newValue);
      }}
      onBlur={onBlur}
      label={label}
      disabled={disabled}
      variant="outlined"
    >
      <MenuItemMUI
        key="null_value"
        value={null}
      >
        -- No selection --
      </MenuItemMUI>
      {menuItems.map((item) => (
        <MenuItemMUI
          key={item.value}
          value={item.value}
        >
          {item.label}
        </MenuItemMUI>
      ))}
    </SelectMUI>
    <FormHelperTextMUI>{helperText}</FormHelperTextMUI>
  </FormControlMUI>
);

MultiSelect.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
    }),
  ),
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
};

MultiSelect.defaultProps = {
  id: '',
  onBlur: () => {},
  menuItems: [],
  disabled: false,
  helperText: '',
};
