import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { Grid } from '@mui/material';
import {
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import {
  FormikTextField,
  FormikSelect,
  FormikPasswordField,
  FormikSwitch,
} from '../InputFields/index';
import Button from '../../Buttons/Button';

const FormikForm = ({
  formRef,
  fields,
  initialValues,
  validationSchema,
  onSubmit,
  onCancel,
  submitLabel,
}) => {
  const hasFocusedRef = useRef(false);

  const fieldComponents = {
    text: FormikTextField,
    password: FormikPasswordField,
    select: FormikSelect,
    switch: FormikSwitch,
  };

  useEffect(() => {
    if (hasFocusedRef.current) return;

    const firstField = fields.find(
      (field) => ['text', 'password', 'select', 'switch'].includes(field.fieldType)
        && !field.disabled,
    );

    if (firstField) {
      requestAnimationFrame(() => {
        document.querySelector(`input[name="${firstField.name}"]`)?.focus();
      });
      hasFocusedRef.current = true;
    }
  }, [fields]);

  const isFieldRequired = useCallback((fieldName) => {
    const fieldSchema = validationSchema?.fields?.[fieldName];
    return fieldSchema?.tests?.some((test) => test.OPTIONS?.name === 'required') || false;
  }, [validationSchema]);

  const processedFields = useMemo(() => fields.map((field) => ({
    ...field,
    label: validationSchema
  && typeof field?.label === 'string'
  && !isFieldRequired(field.name)
      ? `${field.label} (optional)`
      : field.label,
  })), [validationSchema]);

  const renderField = (field) => {
    const { fieldType, fieldContent, ...fieldProps } = field;
    const FieldComponent = fieldComponents[fieldType] || fieldType;

    return FieldComponent
      ? <FieldComponent {...fieldProps}>{fieldContent}</FieldComponent>
      : null;
  };

  return (
    <Formik
      enableReinitialize
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Form>
        <Grid
          container
          direction="column"
        >
          {processedFields.map((field, index) => (
            <Grid
              item
              key={field.name || `field-${index}`}
              sx={{ p: 0.1 }}
            >
              {renderField(field)}
            </Grid>
          ))}
          <Grid
            container
            justifyContent="end"
            gap={1}
          >
            {onCancel && (
            <Grid item>
              <Button
                type="button"
                variant="outlined"
                onClick={onCancel}
                label="Cancel"
              />
            </Grid>
            )}
            <Grid item>
              <Button
                type="submit"
                label={submitLabel}
              />
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
};

FormikForm.propTypes = {
  formRef: PropTypes.shape({
    current: PropTypes.shape({
      resetForm: PropTypes.func,
    }),
  }),
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.shape({
          on: PropTypes.string.isRequired,
          off: PropTypes.string.isRequired,
        }),
      ]),
      fieldType: PropTypes.oneOfType([
        PropTypes.oneOf(['text', 'select', 'password', 'switch']),
        PropTypes.elementType,
      ]).isRequired,
      menuItems: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
        }),
      ),
      disabled: PropTypes.bool,
      fieldContent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
      autoFocus: PropTypes.bool,
    }),
  ).isRequired,
  initialValues: PropTypes.object.isRequired,
  validationSchema: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  submitLabel: PropTypes.string.isRequired,
};

FormikForm.defaultProps = {
  formRef: null,
  onCancel: null,
};

export default FormikForm;
