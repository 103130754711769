import {
  useState, useRef, useMemo, useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { Popover, Grid } from '@mui/material';
import debounce from 'lodash.debounce';
import { SearchField } from '../InputFields';
import Button from '../Buttons/Button';
import FilterFields from './FilterFields';

const Search = ({
  setCurrentPage,
  setSearchedRows,
  rows,
  fields,
  advancedSearch,
  setFilter,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const textFieldRef = useRef(null);
  const [filters, setFilters] = useState(() => fields.reduce((acc, field) => ({
    ...acc,
    [field.id]: [],
  }), { search: '' }));

  useEffect(() => {
    const filterRows = (filter) => rows.filter((row) => row.data.some((item) => {
      const itemValue = String(item.value || '').toLowerCase();
      return itemValue.includes(filter.toLowerCase());
    }));

    if (filters.search.length > 2) {
      setSearchedRows(filterRows(filters.search));
    } else {
      setSearchedRows(rows);
    }
  }, [filters.search, rows]);

  const clearFilters = () => {
    const clearedFilters = fields.reduce((acc, field) => ({
      ...acc,
      [field.id]: [],
    }), { search: '' });
    setFilters(clearedFilters);
    setSearchedRows(rows);
    setCurrentPage(0);
  };

  const advanceFilterRows = useCallback((updatedFilters) => rows.filter(
    (row) => fields.every((field) => {
      const filter = updatedFilters[field.id];
      if (!filter?.length) return true;

      return row.data.some((item) => item.field === field.id && (field.type === 'tags'
        ? filter.some((f) => item.value?.includes(f))
        : filter.some((f) => String(item.value).toLowerCase().includes(f?.toLowerCase()))
      ));
    }),
  ), [fields, rows]);

  const hasFilters = useMemo(
    () => Object.entries(filters).some(([key, value]) => value.length > 0 && value[0] !== '' && key !== 'search'),
    [filters],
  );

  const joinFilters = useMemo(
    () => Object.entries(filters)
      .filter(([key, value]) => key !== 'search' && value.length > 0 && value[0] !== '')
      .map(([key, value]) => `${key}:${value.join(',')}`)
      .join('; '),
    [filters],
  );

  const createDebouncedFilter = () => debounce((value) => {
    setFilters((prev) => ({ ...prev, search: value }));
    setFilter(value);
    setCurrentPage(0);
  }, 100);

  const debouncedSetFilter = useCallback(
    createDebouncedFilter(setFilters, setCurrentPage),
    [setFilters, setCurrentPage],
  );

  return (
    <Grid item>
      <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
        <SearchField
          name="search"
          label={hasFilters ? '' : 'Search'}
          value={hasFilters ? joinFilters : filters.search}
          disabled={hasFilters}
          onChange={(e) => debouncedSetFilter(e.target.value)}
          inputRef={textFieldRef}
          onFilterClick={() => setAnchorEl(textFieldRef.current)}
          advancedSearch={advancedSearch}
        />
        {advancedSearch && (
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Grid
            container
            direction="column"
            p={2}
          >
            {fields.map((field) => (
              <Grid item key={field.id}>
                <FilterFields
                  field={field}
                  rows={rows}
                  values={filters[field.id]}
                  onChange={(value) => setFilters({
                    ...filters,
                    [field.id]: Array.isArray(value) ? value : [value],
                    search: '',
                  })}
                />
              </Grid>
            ))}
            <Grid
              container
              justifyContent="end"
              gap={1}
            >
              <Grid item>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={clearFilters}
                  label="Clear"
                />
              </Grid>
              <Grid item>
                <Button
                  size="small"
                  onClick={() => {
                    setSearchedRows(advanceFilterRows(filters));
                    setCurrentPage(0);
                    setAnchorEl(null);
                  }}
                  label="Apply"
                />
              </Grid>
            </Grid>
          </Grid>
        </Popover>
        )}
      </div>
    </Grid>
  );
};

Search.propTypes = {
  setCurrentPage: PropTypes.func.isRequired,
  setSearchedRows: PropTypes.func.isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({
      field: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
      ]),
    })),
  })).isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  })).isRequired,
  advancedSearch: PropTypes.bool,
  setFilter: PropTypes.func,
};

Search.defaultProps = {
  advancedSearch: true,
  setFilter: () => {},
};

export default Search;
